import React, { useState, useEffect } from "react";
import { Button, Box, Typography, Icon, Tooltip } from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { useWorkspace } from "../../../contexts/WorkspaceContext";
import ppt_template_image1 from "../../../assets/ppt_template_1.png";
import ppt_template_image2 from "../../../assets/ppt_template_2.png";
import ppt_template_image3 from "../../../assets/ppt_template_image3.png";
import ppt_template_image4 from "../../../assets/ppt_template_image4.png";

const SettingsOptionInOneClickGenerateModal = ({ title }) => {
  const {
    audioVoiceObject,
    setShowImageSettings,
    imageRatio,
    setShowPptSettings,
    setShowAudioSettings,
    templateId,
    imageModel,
    imageFormat,
    audioVoice,
    audioLanguage,
    arrayForLinksAvalible,
    selectedLanguage,
    selectedGenVideoVoice,
    selectedAspectRatioForVideoGen,
    selectedGenVideoLanguage,
    selectedWidthForVideoGen,
    selectedHeightForVideoGen,
    currentThread,
    setShowVideoSettings
  } = useWorkspace();

  const themes = [
    {
      src: ppt_template_image1,
      label: "Theme 1",
      value: "theme2",
      title1: "Minimal Pitch Deck Theme",
    },
    {
      src: ppt_template_image2,
      label: "Theme 2",
      value: "theme3",
      title1: "Photography Portfolio Theme",
    },
    {
      src: ppt_template_image3,
      label: "Theme 3",
      value: "theme4",
      title1: "All-hands Presentation Theme",
    },
    {
      src: ppt_template_image4,
      label: "Theme 4",
      value: "theme5",
      title1: "Brand Launch Presentation Theme",
    },
  ];

  return (
    <Box>
      <Box
        sx={{
          margin: "auto",
          marginBottom: "12px",
          marginTop: "20px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            textAlign: "left",
            color: "#FFFFFF",
          }}
        >
          Settings:
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {title === "Image_Settings" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              alignSelf: "left",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "12px" }}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={`Image Ratio : ${imageRatio}`}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M11.6667 14.1693H15.8334V10.0026H14.1667V12.5026H11.6667V14.1693ZM4.16669 10.0026H5.83335V7.5026H8.33335V5.83594H4.16669V10.0026ZM3.33335 16.6693C2.87502 16.6693 2.4828 16.5062 2.15669 16.1801C1.83058 15.854 1.66724 15.4615 1.66669 15.0026V5.0026C1.66669 4.54427 1.83002 4.15205 2.15669 3.82594C2.48335 3.49983 2.87558 3.33649 3.33335 3.33594H16.6667C17.125 3.33594 17.5175 3.49927 17.8442 3.82594C18.1709 4.1526 18.3339 4.54483 18.3334 5.0026V15.0026C18.3334 15.4609 18.1703 15.8534 17.8442 16.1801C17.5181 16.5068 17.1256 16.6698 16.6667 16.6693H3.33335ZM3.33335 15.0026H16.6667V5.0026H3.33335V15.0026Z"
                        fill="#CAC4D0"
                      />
                    </svg>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {imageRatio}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={`Image Style : ${imageModel}`}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.99984 17.3307C7.90549 17.3307 6.82185 17.1152 5.81081 16.6964C4.79976 16.2776 3.8811 15.6638 3.10728 14.89C1.54448 13.3271 0.666504 11.2075 0.666504 8.9974C0.666504 6.78726 1.54448 4.66764 3.10728 3.10484C4.67008 1.54204 6.7897 0.664063 8.99984 0.664062C13.5832 0.664062 17.3332 3.9974 17.3332 8.16406C17.3332 9.49014 16.8064 10.7619 15.8687 11.6996C14.931 12.6373 13.6593 13.1641 12.3332 13.1641H10.8332C10.5832 13.1641 10.4165 13.3307 10.4165 13.5807C10.4165 13.6641 10.4998 13.7474 10.4998 13.8307C10.8332 14.2474 10.9998 14.7474 10.9998 15.2474C11.0832 16.4141 10.1665 17.3307 8.99984 17.3307ZM8.99984 2.33073C7.23173 2.33073 5.53603 3.03311 4.28579 4.28335C3.03555 5.53359 2.33317 7.22929 2.33317 8.9974C2.33317 10.7655 3.03555 12.4612 4.28579 13.7114C5.53603 14.9617 7.23173 15.6641 8.99984 15.6641C9.24984 15.6641 9.4165 15.4974 9.4165 15.2474C9.4165 15.0807 9.33317 14.9974 9.33317 14.9141C8.99984 14.4974 8.83317 14.0807 8.83317 13.5807C8.83317 12.4141 9.74984 11.4974 10.9165 11.4974H12.3332C13.2172 11.4974 14.0651 11.1462 14.6902 10.5211C15.3153 9.89596 15.6665 9.04812 15.6665 8.16406C15.6665 4.91406 12.6665 2.33073 8.99984 2.33073ZM4.4165 7.33073C5.08317 7.33073 5.6665 7.91406 5.6665 8.58073C5.6665 9.2474 5.08317 9.83073 4.4165 9.83073C3.74984 9.83073 3.1665 9.2474 3.1665 8.58073C3.1665 7.91406 3.74984 7.33073 4.4165 7.33073ZM6.9165 3.9974C7.58317 3.9974 8.1665 4.58073 8.1665 5.2474C8.1665 5.91406 7.58317 6.4974 6.9165 6.4974C6.24984 6.4974 5.6665 5.91406 5.6665 5.2474C5.6665 4.58073 6.24984 3.9974 6.9165 3.9974ZM11.0832 3.9974C11.7498 3.9974 12.3332 4.58073 12.3332 5.2474C12.3332 5.91406 11.7498 6.4974 11.0832 6.4974C10.4165 6.4974 9.83317 5.91406 9.83317 5.2474C9.83317 4.58073 10.4165 3.9974 11.0832 3.9974ZM13.5832 7.33073C14.2498 7.33073 14.8332 7.91406 14.8332 8.58073C14.8332 9.2474 14.2498 9.83073 13.5832 9.83073C12.9165 9.83073 12.3332 9.2474 12.3332 8.58073C12.3332 7.91406 12.9165 7.33073 13.5832 7.33073Z"
                        fill="#CAC4D0"
                      />
                    </svg>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {imageModel}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={`Image Format : ${imageFormat}`}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box sx={{}}>
                    <FolderOutlinedIcon
                      sx={{ width: "18px", height: "18px" }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {imageFormat}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>
            </Box>

            <Button
              onClick={() => {
                setShowImageSettings(true);
              }}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                textTransform: "none",
                border: "1px solid rgba(255, 255, 255, 0.10)",
                padding: "8px 12px 8px 12px",
                gap: "10px",
                marginLeft: "20px",
                bgcolor: "#2F355F",
                borderRadius:"8px",
                "&:hover": { bgcolor: "#373E6D" },
                "&:active": { bgcolor: "#2F355F" },
              }}
            >
              <Box
                sx={{
                  width: "17px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                }}
              >
                <SettingsOutlinedIcon sx={{ width: "18px", height: "18px" }} />
              </Box>
              <Box
                sx={{
                  height: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#FFFFFF",
                  }}
                >
                  Change Settings
                </Typography>
              </Box>
            </Button>
          </Box>
        )}
        {(title === "Video_Settings" || title === "VideoGen_Settings") && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              alignSelf: "left",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "12px" }}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={
                  selectedGenVideoVoice == null
                    ? "Voice Name : Matilda, Voice Gender : Female, Voice Accent : American"
                    : `Voice Name : ${selectedGenVideoVoice?.name},Voice Gender : ${selectedGenVideoVoice?.gender},Voice Accent : ${selectedGenVideoVoice?.country}`
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                    >
                      <path
                        d="M7.49998 9.83073C9.34165 9.83073 10.8333 8.33906 10.8333 6.4974C10.8333 4.65573 9.34165 3.16406 7.49998 3.16406C5.65831 3.16406 4.16665 4.65573 4.16665 6.4974C4.16665 8.33906 5.65831 9.83073 7.49998 9.83073ZM7.49998 4.83073C8.41665 4.83073 9.16665 5.58073 9.16665 6.4974C9.16665 7.41406 8.41665 8.16406 7.49998 8.16406C6.58331 8.16406 5.83331 7.41406 5.83331 6.4974C5.83331 5.58073 6.58331 4.83073 7.49998 4.83073ZM7.49998 11.4974C5.27498 11.4974 0.833313 12.6141 0.833313 14.8307V16.4974H14.1666V14.8307C14.1666 12.6141 9.72498 11.4974 7.49998 11.4974ZM2.49998 14.8307C2.68331 14.2307 5.25831 13.1641 7.49998 13.1641C9.74998 13.1641 12.3333 14.2391 12.5 14.8307H2.49998ZM12.5666 4.8724C13.2666 5.85573 13.2666 7.13073 12.5666 8.11406L13.9666 9.5224C15.65 7.83906 15.65 5.2974 13.9666 3.46406L12.5666 4.8724ZM16.725 0.664062L15.3666 2.0224C17.675 4.53906 17.675 8.3224 15.3666 10.9724L16.725 12.3307C19.975 9.08906 19.9833 4.03906 16.725 0.664062Z"
                        fill="#CAC4D0"
                      />
                    </svg>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {selectedGenVideoVoice == null
                        ? "Matilda, Female, American"
                        : `${selectedGenVideoVoice?.name}, ${selectedGenVideoVoice?.gender}, ${selectedGenVideoVoice?.country}`}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={
                  selectedGenVideoLanguage == null
                    ? "Language : English"
                    : `Language : ${selectedGenVideoLanguage?.languageName}`
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box>
                    <LanguageOutlinedIcon
                      sx={{ width: "17px", height: "17px" }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {selectedGenVideoLanguage == null
                        ? "English"
                        : `${selectedGenVideoLanguage?.languageName}`}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={
                  (selectedHeightForVideoGen != null &&
                  selectedWidthForVideoGen != null
                    ? `Video Aspect Ratio : ${selectedHeightForVideoGen}:${selectedWidthForVideoGen}`
                    : "Video Aspect Ratio : 16:9") +
                  " , " +
                  (selectedAspectRatioForVideoGen != null
                    ? `Video Resolution : ${selectedAspectRatioForVideoGen}`
                    : "Video Resolution : 1080p")
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box>
                    <FolderOutlinedIcon
                      sx={{ width: "18px", height: "18px" }}
                    />
                  </Box>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {selectedHeightForVideoGen != null &&
                      selectedWidthForVideoGen != null
                        ? `${selectedHeightForVideoGen} : ${selectedWidthForVideoGen}`
                        : "16:9 ,"}
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {selectedAspectRatioForVideoGen != null
                        ? selectedAspectRatioForVideoGen
                        : "1080p"}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>
            </Box>

            <Button
              onClick={() => {
                setShowVideoSettings(true);
              }}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                textTransform: "none",
                border: "1px solid rgba(255, 255, 255, 0.10)",
                padding: "8px 12px 8px 12px",
                gap: "10px",
                bgcolor: "#2F355F",
                marginLeft: "20px",
                borderRadius:"8px",
                "&:hover": { bgcolor: "#373E6D" },
                "&:active": { bgcolor: "#2F355F" },
              }}
            >
              <Box
                sx={{
                  width: "17px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                }}
              >
                <SettingsOutlinedIcon sx={{ width: "18px", height: "18px" }} />
              </Box>
              <Box
                sx={{
                  height: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#FFFFFF",
                  }}
                >
                  Change Settings
                </Typography>
              </Box>
            </Button>
          </Box>
        )}
        {title === "Presentation_Settings" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              alignSelf: "left",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "12px" }}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={`PPT Template : ${themes[templateId].title1}`}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M11.6667 14.1693H15.8334V10.0026H14.1667V12.5026H11.6667V14.1693ZM4.16669 10.0026H5.83335V7.5026H8.33335V5.83594H4.16669V10.0026ZM3.33335 16.6693C2.87502 16.6693 2.4828 16.5062 2.15669 16.1801C1.83058 15.854 1.66724 15.4615 1.66669 15.0026V5.0026C1.66669 4.54427 1.83002 4.15205 2.15669 3.82594C2.48335 3.49983 2.87558 3.33649 3.33335 3.33594H16.6667C17.125 3.33594 17.5175 3.49927 17.8442 3.82594C18.1709 4.1526 18.3339 4.54483 18.3334 5.0026V15.0026C18.3334 15.4609 18.1703 15.8534 17.8442 16.1801C17.5181 16.5068 17.1256 16.6698 16.6667 16.6693H3.33335ZM3.33335 15.0026H16.6667V5.0026H3.33335V15.0026Z"
                        fill="#CAC4D0"
                      />
                    </svg>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {themes[templateId].title1}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>
            </Box>
            <Button
              onClick={() => {
                setShowPptSettings(true);
              }}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                textTransform: "none",
                border: "1px solid rgba(255, 255, 255, 0.10)",
                padding: "8px 12px 8px 12px",
                gap: "10px",
                bgcolor: "#2F355F",
                marginLeft: "20px",
                borderRadius:"8px",
                "&:hover": { bgcolor: "#373E6D" },
                "&:active": { bgcolor: "#2F355F" },
              }}
            >
              <Box
                sx={{
                  width: "17px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                }}
              >
                <SettingsOutlinedIcon sx={{ width: "18px", height: "18px" }} />
              </Box>
              <Box
                sx={{
                  height: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#FFFFFF",
                  }}
                >
                  Change Settings
                </Typography>
              </Box>
            </Button>
          </Box>
        )}
        {title === "Audio_Settings" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "left",
              alignSelf: "left",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: "12px" }}>
              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={
                  audioVoiceObject == null
                    ? `Audio Voice : Sarah, Female, American`
                    : `Audio Voice : ${audioVoiceObject?.name}, ${audioVoiceObject?.labels?.gender}, ${audioVoiceObject?.labels?.accent}`
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                    >
                      <path
                        d="M7.49998 9.83073C9.34165 9.83073 10.8333 8.33906 10.8333 6.4974C10.8333 4.65573 9.34165 3.16406 7.49998 3.16406C5.65831 3.16406 4.16665 4.65573 4.16665 6.4974C4.16665 8.33906 5.65831 9.83073 7.49998 9.83073ZM7.49998 4.83073C8.41665 4.83073 9.16665 5.58073 9.16665 6.4974C9.16665 7.41406 8.41665 8.16406 7.49998 8.16406C6.58331 8.16406 5.83331 7.41406 5.83331 6.4974C5.83331 5.58073 6.58331 4.83073 7.49998 4.83073ZM7.49998 11.4974C5.27498 11.4974 0.833313 12.6141 0.833313 14.8307V16.4974H14.1666V14.8307C14.1666 12.6141 9.72498 11.4974 7.49998 11.4974ZM2.49998 14.8307C2.68331 14.2307 5.25831 13.1641 7.49998 13.1641C9.74998 13.1641 12.3333 14.2391 12.5 14.8307H2.49998ZM12.5666 4.8724C13.2666 5.85573 13.2666 7.13073 12.5666 8.11406L13.9666 9.5224C15.65 7.83906 15.65 5.2974 13.9666 3.46406L12.5666 4.8724ZM16.725 0.664062L15.3666 2.0224C17.675 4.53906 17.675 8.3224 15.3666 10.9724L16.725 12.3307C19.975 9.08906 19.9833 4.03906 16.725 0.664062Z"
                        fill="#CAC4D0"
                      />
                    </svg>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {audioVoiceObject == null
                        ? `Sarah, Female, American`
                        : `${audioVoiceObject?.name}, ${audioVoiceObject?.labels?.gender}, ${audioVoiceObject?.labels?.accent}`}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#E6E0E9",
                      color: "#17182C",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "4px 8px 4px 8px",
                      borderRadius: "4px",
                      textAlign: "center",
                    },
                  },
                }}
                title={
                  audioLanguage === null
                    ? `Audio Voice : English`
                    : `Audio Voice : ${audioLanguage}`
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: "8px",
                    border: "1px solid rgba(255, 255, 255, 0.10)",
                    padding: "8px 12px 8px 12px",
                    gap: "10px",
                    bgcolor: "",
                  }}
                >
                  <Box>
                    <LanguageOutlinedIcon
                      sx={{ width: "17px", height: "17px" }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        textAlign: "left",
                        color: "#FFFFFF",
                      }}
                    >
                      {audioLanguage === null ? "English" : { audioLanguage }}
                    </Typography>
                  </Box>
                </Box>
              </Tooltip>
            </Box>
            <Button
              onClick={() => {
                setShowAudioSettings(true);
              }}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                textTransform: "none",
                border: "1px solid rgba(255, 255, 255, 0.10)",
                padding: "8px 12px 8px 12px",
                gap: "10px",
                bgcolor: "#2F355F",
                marginLeft: "20px",
                borderRadius:"8px",
                "&:hover": { bgcolor: "#373E6D" },
                "&:active": { bgcolor: "#2F355F" },
              }}
            >
              <Box
                sx={{
                  width: "17px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                }}
              >
                <SettingsOutlinedIcon sx={{ width: "18px", height: "18px" }} />
              </Box>
              <Box
                sx={{
                  height: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#FFFFFF",
                  }}
                >
                  Change Settings
                </Typography>
              </Box>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default SettingsOptionInOneClickGenerateModal;
