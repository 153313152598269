import React, { useEffect, useState } from 'react'
import Sidebar from '../components/workspace/sideBar/Sidebar';
import CreditRechargeModal from '../components/CreditRechargeModal';
import AllDelete from './AllDelete';
import LogOut from './LogOut';
import CommonToast from '../components/Common_Components/CommonToast';
import { Navigate} from "react-router-dom";
import { useWorkspace } from '../contexts/WorkspaceContext';
import { fetchAllHistory } from '../contexts/WorkspaceUtils';
import { useParams } from "react-router-dom";
import MessageWindowForTrackWebsite from '../components/workspace/sideBar/Track_Website/MessageWindowForTrackWebsite';
import ImageSettings from '../components/workspace/settingBar/ImageSettings/ImageSettings';
import PptTemplates from '../components/workspace/settingBar/PptTemplates';
import AudioSettings from '../components/workspace/settingBar/AudioSettings/AudioSettings';
import VideoSettings from '../components/workspace/settingBar/VideoSettings';

const WebSiteAllUpdatePage = () => {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const { id } = useParams();

    const { Today, setYesterday,setThisWeek,setThisMonth, setPrevMonth, setToday, setCurrentHistoryId, updateWebList,setUpdateWebList, currentWebsiteLinkedId, setCurrentWebsiteLinkedId, currentThread, currentWebs,setCurrentWebs, refetchClicked,setRefetchClicked} = useWorkspace();

      const handleRefetchClicked = () => {
        setRefetchClicked(!refetchClicked);
      };

      const handleUpdateList = () => {
        setUpdateWebList(!updateWebList);
      };

    
    useEffect(() => {
      if(currentThread.length === 0){
        fetchAllHistory(Today, setToday, setYesterday, setThisWeek, setThisMonth, setPrevMonth, setCurrentHistoryId)
      }
    }, [])

    useEffect(()=>{
      if(id){
        setCurrentWebsiteLinkedId(id)
      }
    })

  return (
    isLoggedIn ? 
    <div style={{ display: 'flex', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      <Sidebar />
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
      <MessageWindowForTrackWebsite website={currentWebs} onHandleRefetch={handleRefetchClicked} refetchClicked={refetchClicked} updateWebList={handleUpdateList} />
        <CreditRechargeModal />
        <AllDelete/>
        <LogOut/>
        <CommonToast />
      </div>
          <ImageSettings />
          <PptTemplates />
          <AudioSettings />
          <VideoSettings />
    </div> 
    : <Navigate to={"/"} /> 
  )
}

export default WebSiteAllUpdatePage