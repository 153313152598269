// import React from 'react'
// import { Box, Divider, Typography,  } from "@mui/material";
// import Info from "../../../../assets/info.png";
// import Web from '../../../../assets/web-plus.png'
// import can from '../../../../assets/cd.png'
// import deleteIcon from '../../../../assets/DeleteIcon.png'
// const Default_WebTrackScreen = () => {
//   return (
//     <div>
//             <Box sx={{ margin: "0px 0px", width:"100%" }}>
//                 <Box sx={{display:"flex", flexDirection:"row", marginLeft:'30%', justifyContent:"space-between", padding: "0px 78px"}}> 
//                 <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center"}}>
//                 <Box>
//                     <img src={can} alt="website" />
//                 </Box>
//                 <Typography sx={{
//                     fontFamily: 'Inter',
//                     fontSize: '14px',
//                     fontWeight: 400,
//                     lineHeight: '20px',
//                     letterSpacing: '0.1px',
//                     textAlign: 'left',
//                     color: '#FFFFFF',
//                     whiteSpace: 'nowrap',
//                     }}>Immigration and Citizenship - Canada.ca</Typography>
//                 </Box>

                
//                 <Box sx={{width:"48px", height:"48px", backgroundColor:"#1F233E", display:"flex", justifyContent:"center", alignItems:"center", borderRadius:"50%"}}>
//                 <img src={deleteIcon} alt="delete" sx={{opacity:0.8}}/>
//                 </Box>
                
//                 </Box> 

//                 <Divider sx={{bgcolor:"#FFFFFF", opacity: 0.2, margin:"0px", padding:"0px 0px"}}/>

//                 <Box sx={{width:"100%", display:"flex",
//                     justifyContent:"center",
//                     alignItems:"center",
//                     marginTop:"100px",
//                     marginBottom:"100px",
//                     padding: "0px 78px"
//                 }}>
//                 <Box sx={{maxWidth:"312px", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
//                 <Box>
//                     <img src={Web} alt="web" />
//                 </Box>
//                 <Box>
//                 <Typography sx={{
//                     fontFamily: 'Inter',
//                     fontSize: '24px',
//                     fontWeight: 500,
//                     lineHeight: '24px',
//                     letterSpacing: '0.1px',
//                     textAlign: 'left',
//                     color: '#FFFFFF',
//                     marginTop: 3

//                 }}>No updates yet</Typography></Box>
//                 <Box>
//                 <Typography sx={{
//                     fontFamily: 'Inter',
//                     fontSize: '14px',
//                     fontWeight: 400,
//                     lineHeight: '24px',
//                     letterSpacing: '0.1px',
//                     color: '#FFFFFF',
//                     marginTop: 2,
//                     opacity:0.8,
//                     textAlign:"center",
//                 }}>ModalX is tracking this webpage and will notify you when there is an update. You will be able to view all webpage updates here.</Typography></Box>
//                 </Box></Box>
                  

//                 <Box sx={{width:'720px',margin:'auto'}}>
//                     <Box sx={{
//                         display: "flex",
//                         flexDirection: "row",
//                         fontFamily: "Inter",
//                         fontSize: "12px",
//                         fontWeight: 400,
//                         lineHeight: "140%",
//                         textAlign: "left",
//                         gap: "8px",
//                         color: "rgba(255, 255, 255, 0.80)",
//                     }}>
//                         <Box>
//                             <img src={Info} alt="info" />
//                         </Box>

//                         <Box>
//                             {`ModalX will track changes in text content on individual webpages such as example.com/policy, or example.com (home page). It will not track changes across the entire website 'example.com' or changes in styles or layouts.`}
//                         </Box>
//                     </Box>
//                 </Box>

//             </Box>
//         </div>
//   )
// }

// export default Default_WebTrackScreen

import React from 'react'
import { Box, Divider, Typography,  } from "@mui/material";
import Info from "../../../../assets/info.png";
import Web from '../../../../assets/web-plus.png'
import can from '../../../../assets/cd.png'
import deleteIcon from '../../../../assets/DeleteIcon.png'
import { useWorkspace } from "../../../../contexts/WorkspaceContext";

const Default_WebTrackScreen = () => {
  const {selectedWeb} = useWorkspace();
  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', margin: "0px 0px", width: "100%" }}>
        <Box sx={{ flexGrow: 1 }}> {/* Everything above the info section */}
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px 78px"}}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center",margin:'auto'}}>
              <Box>
                <img src={can} alt="website" />
              </Box>
              <Typography sx={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '20px',
                letterSpacing: '0.1px',
                textAlign: 'left',
                color: '#FFFFFF',
                whiteSpace: 'nowrap',
              }}>{selectedWeb}</Typography>
            </Box>

            {/* <Box sx={{ width: "48px", height: "48px", backgroundColor: "#1F233E", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%" }}>
              <img src={deleteIcon} alt="delete" sx={{ opacity: 0.8 }} />
            </Box> */}
          </Box>

          <Divider sx={{ bgcolor: "#FFFFFF", opacity: 0.2, margin: "0px", padding: "0px 0px" }} />

          <Box sx={{
            width: "100%", display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "100px",
            marginBottom: "100px",
            padding: "0px 78px"
          }}>
            <Box sx={{ maxWidth: "312px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <Box>
                <img src={Web} alt="web" />
              </Box>
              <Box>
                <Typography sx={{
                  fontFamily: 'Inter',
                  fontSize: '24px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.1px',
                  textAlign: 'left',
                  color: '#FFFFFF',
                  marginTop: 3

                }}>No updates yet</Typography></Box>
              <Box>
                <Typography sx={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.1px',
                  color: '#FFFFFF',
                  marginTop: 2,
                  opacity: 0.8,
                  textAlign: "center",
                }}>ModalX is tracking this webpage and will notify you when there is an update. You will be able to view all webpage updates here.</Typography></Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: '720px', margin: 'auto',paddingBottom:'40px' }}> {/* This section now dynamically stays at the bottom */}
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "140%",
            textAlign: "left",
            gap: "8px",
            color: "rgba(255, 255, 255, 0.80)",
          }}>
            <Box>
              <img src={Info} alt="info" />
            </Box>

            <Box>
              {`ModalX will track changes in text content on individual webpages such as example.com/policy, or example.com (home page). It will not track changes across the entire website 'example.com' or changes in styles or layouts.`}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default Default_WebTrackScreen
